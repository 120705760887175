import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import axios from "axios";
import Modal from "./Modal";
import { FormContext } from "../../App";

const BeginRedemption = () => {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);
  const [invalidCombination, setInvalidCombination] = useState(false);
  const [voucherRedeemed, setVoucherRedeemed] = useState(false);

  const renderError = (message) => (
    <p className="text-red-600 font-bold">{message}</p>
  );

  const closeModal = () => {
    setVoucherRedeemed(false);
    setInvalidCombination(false);
    setFormData(null);
  };

  const handleSubmit = async (values, { setFieldError, resetForm }) => {
    const { VoucherNumber, ZipCode } = values;

    if (!VoucherNumber || !ZipCode) {
      if (!VoucherNumber) {
        setFieldError("VoucherNumber", "Voucher Number is required");
      }
      if (!ZipCode) {
        setFieldError("ZipCode", "ZipCode is required");
      }
    } else {
      const data = { ...formData, ...values };
      try {
         const response = await axios.post("https://fa-prod-prod-microservices-centralus.azurewebsites.net/api/PostVoucherDetail?code=wZEkaax9WzflCPADwGwlhFj66iYsyev4V9ov9ljshFFaEKQAZOPcjg==&clientId=aurora", data);
        if (response.status === 200) {
          resetForm();
          const data = { ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        } else {
          console.log(response.data);
        }
      } catch (error) {
        if (error.response.data === "Voucher has been redeemed already.") {
          setVoucherRedeemed(true);
        } else {
          setInvalidCombination(true);
        }
        resetForm();
      }
    }
  };

  const handleVoucherNumberChange = (event, setFieldValue) => {
    const { value } = event.target;
    if (value) {
      setInvalidCombination(false);
    }
    setFieldValue("VoucherNumber", value);
  };

  return (
    <Formik
      initialValues={{
        VoucherNumber: "",
        ZipCode: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ handleChange, setFieldValue }) => (
        <Form
          autoComplete="off"
          className="items-center min-h-screen bg-gray-100"
        >
          <div className="max-w-2xl mx-auto p-6 bg-white rounded shadow">
            <div className="text-3xl font-medium self-center mb-8 mt-6">
              LeafFilter North, LLC, Case No. 2:20-cv-6229 Voucher Login Page
            </div>
            <div className="text-left mb-4">
              <p className="text-gray-700 mb-4">
                You have reached our voucher redemption page for Zilinsky, et
                al. v. LeafFilter North, LLC, Case No. 2:20-cv-6229, in the
                District Court for the Southern District of Ohio for LeafFilter
                Gutter Protection. Thank you for visiting.
              </p>
              <p className="text-gray-700 mb-4">
                Your voucher can be redeemed here by inputting your voucher #
                and zip code. Once you redeem this voucher and submit
                electronically, your voucher cleaning will be scheduled within
                30 days.
              </p>
              <p className="text-red-600 font-bold underline mb-4">
                If you do not wish to schedule a cleaning within the next 30
                days, do not submit your voucher at this time. You should only
                redeem within 30 days of your desired cleaning date.
              </p>
              <p className="text-gray-700 mb-4">
                Once a voucher is redeemed, please do not attempt to redeem any
                additional vouchers for at least 30 days. By providing your
                email address when prompted, this will ensure a smoother process
                in scheduling and service with our Customer Service Team. Please
                see steps below to move forward with redeeming your voucher
                successfully.
              </p>
              <ol className="list-decimal pl-6 mb-4">
                <li className="text-gray-700 mb-2">
                  Input your information below and click BEGIN VOUCHER
                  REDEMPTION (Note: Each voucher can only be claimed once)
                </li>
                <li className="text-gray-700 mb-2">
                  You will be directed to a page to confirm your account and
                  best contact information, including your email address.
                </li>
                <li className="text-gray-700 mb-2">
                  You will also be asked to provide some details on your voucher
                  cleaning needs (Please include area, height on the home, etc.
                  to best inform our Customer Service Team)
                </li>
                <li className="text-gray-700 mb-2">
                  SUBMIT by clicking 'SUBMIT VOUCHER'
                </li>
                <li className="text-gray-700 mb-2">
                  You will be directed to a confirmation page and receive a
                  confirmation email (If email address is provided) that your
                  submission is complete.
                </li>
                <li className="text-gray-700 mb-2">
                  Expect our Customer Care Team to contact you within 3 business
                  days.
                </li>
                <li className="text-gray-700 mb-2">
                  We will schedule your cleaning with our representatives for a
                  visit to occur within 30 DAYS of redeeming this voucher.
                </li>
              </ol>
              <p className="text-gray-700 mb-4">
                PLEASE NOTE: By inputting your voucher # and information, you
                are entering your claim to receive our free gutter cleaning
                service within the next 30 days of this retrieval.{" "}
                <span className="text-red-600 font-bold underline">
                  If you will not be available during this time, please wait to
                  submit until such a time when you would be available.
                </span>
              </p>
              <p className="text-gray-700 mb-4">
                QUESTIONS? Please refer to your correspondence from your class
                administrator. You may also reach out to us through our online
                Contact Us Form at{" "}
                <a
                  href="https://www.leaffilter.com/contact/"
                  className="text-blue-500 underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.leaffilter.com/contact/
                </a>
              </p>
            </div>

            <div className="flex flex-col items-center mb-4">
              <label className="font-medium text-gray-900">
                Voucher Number
                <span className="text-red-500 font-bold"> *</span>
              </label>
              <Field
                name="VoucherNumber"
                className="rounded-md border-2 p-2 w-60"
                placeholder="Please Enter Voucher Number"
                onChange={(event) =>
                  handleVoucherNumberChange(event, setFieldValue)
                }
              />
              <ErrorMessage
                name="VoucherNumber"
                component={() =>
                  renderError("Please enter the voucher number!")
                }
              />
            </div>
            <div className="flex flex-col items-center mb-4">
              <label className="font-medium text-gray-900">
                ZipCode<span className="text-red-500 font-bold"> *</span>
              </label>
              <Field
                name="ZipCode"
                className="rounded-md border-2 p-2 w-60"
                placeholder="Please Enter ZipCode"
              />
              <ErrorMessage
                name="ZipCode"
                component={() => renderError("Please enter the ZipCode!")}
              />
            </div>
            <div className="flex flex-col items-center mb-4">
              {invalidCombination &&
                renderError("Invalid Voucher Number and zip code combination!")}
              <button
                className="rounded-md bg-cyan-500 hover:bg-cyan-600 font-medium text-white my-2 p-2"
                type="submit"
              >
                BEGIN VOUCHER REDEMPTION
              </button>
            </div>
            {/* Modal */}
            {voucherRedeemed && (
              <Modal open={voucherRedeemed} onClose={closeModal} />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BeginRedemption;